import React, {useState,useEffect, lazy, Suspense} from "react"
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
const Main = lazy(async () => await import("../components/main/main"));

const IndexPage = ({data}) => {
  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    setHasMounted(true);
  }, []);
  if (!hasMounted) return null;
  const { tags } = data.datoCmsSite.faviconMetaTags
  const renderedTags = tags.map((tag)=>(
      {
          link:<link key={tag.attributes.rel + '-' + tag.attributes.sizes} type="image/x-icon" sizes={tag.attributes.sizes} rel={tag.attributes.rel} href={tag.attributes.href}/>, 
          meta:<meta key={tag.attributes.rel + '-' + tag.attributes.sizes} name={tag.attributes.name}content={tag.attributes.content} />
      }[tag.tagName]
    )
  )
  return (
    <main>
      <Suspense fallback={<div></div>}>
        <Helmet>
        <meta charset="utf-8"/>
        <title>Central Tech Kenora | Cell Phone, Tablet and Computer Repairs | Kenora Ontario</title>
        <meta name="description" content="Central Tech is your one stop shop for electronic repairs.  Our friendly experts can repair any model of iPhone, Android, Huawei or other brands of cellular phone or tablet you may have.  With over 30 years of experience in computer repair, be assured that your PC or Mac will be restored to the best condition possible.  It doesn't stop there though, if you are experiencing issues with any electronic device, we can fix it.  Central Tech - Kenora's best electronic repair company."/>
        <meta name="image" content="https://www.datocms-assets.com/48799/1622047534-logo.webp"/>
        <meta itemprop="name" content="Central Tech"/>
        <meta itemprop="description" content="Central Tech is your one stop shop for electronic repairs.  Our friendly experts can repair any model of iPhone, Android, Huawei or other brands of cellular phone or tablet you may have.  With over 30 years of experience in computer repair, be assured that your PC or Mac will be restored to the best condition possible.  It doesn't stop there though, if you are experiencing issues with any electronic device, we can fix it.  Central Tech - Kenora's best electronic repair company."/>
        <meta itemprop="image" content="https://www.datocms-assets.com/48799/1622047534-logo.webp"/>
        <meta name="og:title" content="Central Tech"/>
        <meta name="og:description" content="Central Tech is your one stop shop for electronic repairs.  Our friendly experts can repair any model of iPhone, Android, Huawei or other brands of cellular phone or tablet you may have.  With over 30 years of experience in computer repair, be assured that your PC or Mac will be restored to the best condition possible.  It doesn't stop there though, if you are experiencing issues with any electronic device, we can fix it.  Central Tech - Kenora's best electronic repair company."/>
        <meta name="og:url" content="https://central-tech.netlify.app/"/>
        <meta name="og:site_name" content="Central Tech"/>
        <meta name="og:locale" content="en_CA"/>
        <meta name="og:type" content="website"/>
        <link rel="canonical" href="https://www.centraltech.ca"/>
          { renderedTags }
          <script type="application/ld+json">
            {`
                {
          "@context": "https://schema.org",
          "@type": "LocalBusiness",
          "name": "Central Tech",
          "image": "https://www.datocms-assets.com/48799/1622047534-logo.webp",
          "@id": "",
          "url": "https://www.centraltech.ca/",
          "telephone": "8074641525",
          "priceRange": "0-1000",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "223 1st Street S",
            "addressLocality": "Kenora",
            "addressRegion": "ON",
            "postalCode": "P9N1C2",
            "addressCountry": "CA"
          },
          "geo": {
            "@type": "GeoCoordinates",
            "latitude": 49.76730999999999,
            "longitude": -94.489014
          },
          "openingHoursSpecification": {
            "@type": "OpeningHoursSpecification",
            "dayOfWeek": [
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday"
            ],
            "opens": "09:00",
            "closes": "17:00"
          },
          "sameAs": "https://www.facebook.com/CentralTechKenora/" 
        }
              `}
          </script>
        </Helmet>
          <Main data={data.datoCmsMain}/>
      </Suspense>
    </main>
  )
}

export default IndexPage

export const query = (graphql`
  query {
    datoCmsSite {
      faviconMetaTags{
        tags
      }
    }
    datoCmsMain {
      navbar{
        smallLogo{
          sizes(maxWidth: 60, imgixParams:{fm:"webp"}){
            ...GatsbyDatoCmsSizes
          }
        }
      }
      header{
        backgroundImage{
            sizes(maxWidth: 1920, imgixParams: { fm: "webp" }) {
                ...GatsbyDatoCmsSizes
            }
        }
        logo{
          sizes(maxWidth: 500, imgixParams: {fm:"webp"}){
            ...GatsbyDatoCmsSizes
          }
        }     
      }
      missionStatement{
        statement,
        wordsToHighlight
      }
      serviceGroups{
        name
        image{
          sizes(maxWidth: 920, imgixParams: {fm:"webp"}){
          ...GatsbyDatoCmsSizes
          }
        }
        services
      }
      ad{
        image{
          sizes(maxWidth:225, imgixParams: {fm:"webp"}){
            ...GatsbyDatoCmsSizes
          }
        }
        fullText
        emptyText
      }
      testimonials{
        id
        testimonial
        author
      }
      dropAd{
        activities
        image{
          sizes(maxWidth:350, imgixParams: {fm:"webp"}){
            ...GatsbyDatoCmsSizes
          }
        }
      }
    }
  }
`)